import Mapper from './Mapper';
import { ContractPOResponseDTO, CreateContractPORequestDTO } from 'typings/dto/contract';
import { ServiceProviderResponseDTO } from 'typings/dto/serviceProvider';

export default class ContractPOMapper extends Mapper<ContractPO, ContractPOResponseDTO, CreateContractPORequestDTO> {
  constructor() {
    super(
      {
        enterprise: 'enterprise',
        history: 'history',
        inviteEmail: 'inviteEmail',
        pricelist: 'pricelist',
        serviceCategories: 'regionServiceCategories',
        platformOperator: 'platformOperator',
        status: 'status',
        suspendedByTenant: 'suspendedByTenant',
        suspendedByEnterprise: 'suspendedByEnterprise',
        technicalName: 'technicalName',
        orderTypes: 'orderTypes',
        settings: 'settings',
        legalEntity: 'legalEntity',
        brand: 'brand',
        agreementType: 'agreementType',
        integrationNumber: 'integrationNumber',
      },
      null
    );
  }

  public responseDTOToModel = (dto: ContractPOResponseDTO): ContractPO => {
    const outerMappers = this.getOuterMappers();
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      history: dto.history.map(outerMappers.orderHistory.responseDTOToModel),
      pricelist: outerMappers.pricelistPlatformOperator.responseDTOToModel(dto.pricelist),
      regionServiceCategories: dto.serviceCategories.map((i) => ({
        categories: i.serviceCategories.map(outerMappers.serviceCategory.responseDTOToModel),
        region: i.region,
      })),
    };
  };

  public responseStatisticsDTOToModel = (dto: ServiceProviderResponseDTO['statistics']): User.TechnicianStatistics => {
    const outerMappers = this.getOuterMappers();
    return {
      ...outerMappers.contractSP.responseStatisticsDTOToModel(dto),
    };
  };
}
