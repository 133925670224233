import { CallNotificationResponseDTO, CreateCallNotificationRequestDTO } from 'typings/dto/call';
import Mapper from './Mapper';
import DIContainer from 'services/DIContainer';

export default class CallNotificationMapper extends Mapper<
  CallNotification,
  CallNotificationResponseDTO,
  CreateCallNotificationRequestDTO
> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        callType: 'callType',
        callRecordingLink: 'callRecordingLink',
        participants: 'participants',
        callerId: null,
        orderNumber: 'orderNumber',
        orderId: 'orderId',
        success: 'success',
        duration: 'duration',
        region: 'region',
        customer: 'customer',
        owner: 'owner',
        number: 'number',
        serviceProvider: 'serviceProvider',
        platformOperator: 'platformOperator',
        status: 'status',
        visitIntervals: 'visitDateIntervals',
        brand: 'brand',
        technicians: 'technicians',
        history: 'history',
        review: 'review',
        type: 'type',
        report: 'reports',
        tasks: 'tasks',
        comments: 'comments',
        address: 'address',
        departments: 'departments',
        legalEntity: 'legalEntity',
        techniciansNumberRequired: 'numberOfTechnicians',
        serviceItems: 'services',
        currency: 'currency',
        assignInfo: null,
        isFollowed: 'isFollowed',
        labels: 'labels',
        productDeliveryIntervals: 'deliveryIntervals',
        relations: 'relations',
        customFields: 'customFields',
        techniciansRewardSettings: 'techniciansRewardSettings',
        technicianTeam: 'technicianTeam',
        providerTransactions: 'providerTransactions',
        servicesTechniciansRewards: null,
        reclamationOrders: 'reclamationOrders',
        linkedOrders: 'linkedOrders',
        documents: 'documents',
        tables: 'tables',
        customerSignature: 'customerSignature',
      },
      null
    );
  }

  public responseDTOToModel = (dto: CallNotificationResponseDTO): CallNotification => {
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      phone: this.subEntitiesMappers.phone.responseDTOToModel(dto.callerId),
      participants: dto.participants.map((p) => {
        return {
          ...p,
          phone: p.phoneNumber ? this.subEntitiesMappers.phone.responseDTOToModel(p.phoneNumber) : undefined,
        };
      }),
    };
  };
}
