import { BACKEND_ROOT } from 'configs/apis';

// TODO нужно переделать бекенд пути на манер фронтовых путей (классами с геттерами путей), т.к. много путей, которые из нескольких id склеиваются
const PATH = {
  orderLabel: {
    root: 'tenant-labels',
  },
  fundsTransaction: {
    root: 'funds-transactions',
  },
  client: {
    root: 'clients',
    orders: 'orders',
    brands: 'brands',
    review: 'review',
    comment: 'comment',
    acquiringSettings: 'acquiring-settings',
  },
  action: {
    root: 'actions',
    start: 'start',
  },
  common: {
    subEntity: 'sub',
    list: 'list',
    subscribeOnUpdates: 'subscribe',
    ids: 'ids',
  },
  auth: {
    root: 'auth',
    getCurrent: 'me',
    signin: 'login',
    resetPassword: 'reset-password',
    signout: 'logout',
    changeCredentials: 'change-login-creds',
    verifyCredentials: 'confirmation',
    profile: 'profile',
    changePassword: 'change-password',
    invitation: 'invitation',
  },
  contractSP: {
    root: 'contracts/sp',
  },
  contractPO: {
    root: 'contracts/po',
  },
  otp: {
    root: 'otp',
  },
  user: {
    root: 'user-profiles',
    notifications: 'notification-settings',
    managers: 'managers',
    technician: 'technician',
  },
  notifications: {
    root: 'tenants',
    notifications: 'notification-settings',
    managers: 'managers',
    technician: 'technician',
  },
  userEnterprise: {},
  userSP: {
    availability: 'availability',
  },
  price: {
    client: 'client-prices',
    technician: 'technician-prices',
    serviceProvider: 'service-provider-pricelists',
    platformOperator: 'platform-operator-pricelists',
    settings: 'settings',
    priceItem: 'prices',
  },
  service: {
    root: 'service-item',
  },
  technicianTeam: {
    root: 'technician-teams',
  },
  serviceCategory: {
    root: 'service-category',
  },
  serviceCategoryGroup: {
    root: 'service-category-groups',
  },

  serviceSubscription: {
    root: 'svc-subscription',
    group: 'groups',
  },
  brand: {
    root: 'brands',
    brandForClientPage: 'clients/settings/order',
  },
  serviceProvider: {
    root: 'service-providers',
    invite: 'invite',
  },
  region: {
    root: 'regions',
    regionTenant: 'regions/tenant',
  },
  call: {
    root: 'call/history',
    subscribeOnUpdates: 'call/subscribe'
  },
  serviceOrdered: {
    root: 'service-item',
    payment: 'payment',
    decline: 'decline',
    accept: 'accept',
    discount: 'price/discount',
  },
  // Общие части путей для инвойсов
  invoice: {
    root: 'invoices',
    provider: 'provider',
    po: 'po',
    additionalTransactions: 'additional-transactions',
    orderTransactions: 'order-transactions',
    cancel: 'cancel',
    confirm: 'confirm',
    generate: 'generate',
    export: 'export',
    filterProviders: 'filter/invoice/providers',
    filterCustomers: 'filter/invoice/customers',
    invoiceProviders: 'invoice-providers',
    invoiceCustomers: 'invoice-customers',
    invoiceOrders: 'invoice-orders',
    invoiceAdditionalTransactions: 'invoice-additional-transactions',
  },
  invoiceProvider: {
    root: 'invoices/provider',
  },
  invoicePO: {
    root: 'invoices/po',
  },
  order: {
    root: 'orders',
    review: 'review',
    report: 'report',
    task: 'task',
    comments: 'comment',
    reviewComments: 'comment',
    follow: 'follow',
    followAll: 'followAll',
    assign: 'assign',
    distribution: 'distribution',
    offer: 'offer',
    filter: 'filter',
    balance: 'customer/balance',
    document: 'document',
    customerSignature: 'customer/signature/verification',
  },

  enterprise: {
    root: 'enterprises',
    currency: 'currencies',
    ownServiceCompanies: 'own-sc',
    settings: 'settings',
    webhook: 'webhook',
    order: 'order',
    api: 'api',
  },
  enterpriseDepartment: {
    root: 'departments',
  },
  platformOperator: {
    root: 'platform-operator',
    currency: 'currencies',
    ownServiceCompanies: 'own-sc',
    settings: 'settings',
    webhook: 'webhook',
    order: 'order',
    api: 'api',
  },
  legalEntity: {
    root: 'legal-entities',
  },
  orderHistory: {
    root: 'order-history',
  },
  orderProviderTransaction: {
    root: 'transactions',
  },
  orderType: {
    root: 'order-types',
  },
  orderView: {
    root: 'order-views',
    priority: 'priority',
  },
  // serverEvent: {
  //   root: '/server-events',
  //   task: {
  //     root: '/task',
  //     all: '/all',
  //     order: '/order',
  //   },
  //   order: {
  //     root: '/order',
  //     all: '/all',
  //   },
  // },
  file: {
    root: 'file-storage',
  },
  notification: {
    root: 'notifications',
    urgent: 'urgent',
  },
  country: {
    root: 'countries',
  },
  promotionsConfig: {
    root: 'promotions/order',
    config: 'config',
  },
  dictionary: {
    root: 'dictionaries',
  },
  recommendationsConfig: {
    root: 'recommendations/order',
    config: 'config',
  },
  matchingConfig: {
    root: 'service-item/matching',
  },
};

export const PATH_BACKEND_PART = PATH;

export const PATH_BACKEND = {
  root: BACKEND_ROOT,
  country: {
    root: getFullPath(PATH.country.root),
  },
  orderLabel: {
    root: getFullPath(PATH.orderLabel.root),
    orderFilter: getFullPath(PATH.order.filter, PATH.enterprise.order, 'tenant-labels'),
  },
  client: {
    root: getFullPath(PATH.client.root),
    order: getFullPath(PATH.client.root, PATH.client.orders),
    brand: getFullPath(PATH.client.root, PATH.client.brands),
  },
  action: {
    root: getFullPath(PATH.action.root),
    start: getFullPath(PATH.action.root, PATH.action.start),
  },
  otp: {
    root: getFullPath(PATH.otp.root),
  },
  fundsTransaction: {
    root: getFullPath(PATH.fundsTransaction.root),
  },
  contractSP: {
    root: getFullPath(PATH.contractSP.root),
    accept: getFullPath(PATH.contractSP.root, 'sign'),
    suspend: getFullPath(PATH.contractSP.root, 'suspend'),
    resume: getFullPath(PATH.contractSP.root, 'resume'),
    resendInvite: getFullPath(PATH.contractSP.root, 'invite-resend'),
  },
  contractPO: {
    root: getFullPath(PATH.contractPO.root),
    accept: getFullPath(PATH.contractPO.root, 'sign'),
    suspend: getFullPath(PATH.contractPO.root, 'suspend'),
    resume: getFullPath(PATH.contractPO.root, 'resume'),
  },
  user: {
    root: getFullPath(PATH.user.root),
    notifications: getFullPath(PATH.user.root, PATH.user.notifications),
    disable: getFullPath(PATH.user.root, 'status'),
    all: getFullPath(PATH.user.root, 'all'),
    idNameOnly: getFullPath(PATH.user.root, 'allShort'),
  },
  notifications: {
    root: getFullPath(PATH.notifications.root),
    notifications: getFullPath(PATH.notifications.root, PATH.notifications.notifications),
    disable: getFullPath(PATH.notifications.root, 'status'),
    all: getFullPath(PATH.notifications.root, 'all'),
    idNameOnly: getFullPath(PATH.notifications.root, 'allShort'),
  },
  userEnterprise: {},
  userSP: {},
  auth: {
    root: getFullPath(PATH.auth.root),
    getCurrentUser: getFullPath(PATH.auth.root, PATH.auth.getCurrent),
    signin: getFullPath(PATH.auth.root, PATH.auth.signin),
    signout: getFullPath(PATH.auth.root, PATH.auth.signout),
    resetPassword: getFullPath(PATH.auth.root, PATH.auth.resetPassword),
    verifyCredentials: getFullPath(PATH.auth.root, PATH.auth.verifyCredentials),
    changeCredentials: getFullPath(PATH.auth.root, PATH.auth.changeCredentials),
    changePassword: getFullPath(PATH.auth.root, PATH.auth.changePassword),
    resendInvite: getFullPath(PATH.auth.root, PATH.auth.invitation),
  },
  priceClient: {
    root: getFullPath(PATH.price.client),
  },
  priceTechnician: {
    root: getFullPath(PATH.price.technician),
  },
  priceServiceProvider: {
    root: getFullPath(PATH.price.serviceProvider),
  },
  pricelistServiceProvider: {
    root: getFullPath(PATH.price.serviceProvider),
  },
  pricelistPlatformOperator: {
    root: getFullPath(PATH.price.platformOperator),
  },
  technicianTeam: {
    root: getFullPath(PATH.technicianTeam.root),
  },
  platformOwnerService: {
    root: getFullPath(PATH.service.root),
  },
  platformOwnerServiceCategory: {
    root: getFullPath(PATH.serviceCategory.root, ''),
  },
  platformOwnerServiceCategoryGroup: {
    root: getFullPath(PATH.serviceCategoryGroup.root, ''),
    currency: getFullPath(PATH.serviceCategoryGroup.root, '', PATH.common.subEntity, ''),
  },
  enterpriseCustomService: {
    root: getFullPath(PATH.service.root),
  },
  enterpriseServiceCategoryNative: {
    root: getFullPath(PATH.serviceSubscription.root),
  },
  enterpriseServiceCategoryCustom: {
    root: getFullPath(PATH.serviceCategory.root, ''),
  },
  enterpriseServiceCategoryGroupCustom: {
    root: getFullPath(PATH.serviceCategoryGroup.root, ''),
  },
  enterpriseServiceCategoryGroupNative: {
    root: getFullPath(PATH.serviceSubscription.root, PATH.serviceSubscription.group),
  },
  // Общие пути для инвойсов
  invoice: {
    filterProviders: getFullPath(PATH.invoice.filterProviders),
    filterCustomers: getFullPath(PATH.invoice.filterCustomers),
  },
  invoiceProvider: {
    root: getFullPath(PATH.invoiceProvider.root),
  },
  invoicePO: {
    root: getFullPath(PATH.invoicePO.root),
    customersTenants: getFullPath(PATH.invoice.invoiceCustomers, PATH.invoice.po, 'tenants'),
  },
  orderHistory: {
    root: getFullPath(PATH.orderHistory.root),
  },
  brand: {
    root: getFullPath(PATH.brand.root),
    orderFilter: getFullPath(PATH.order.filter, PATH.enterprise.order, 'brands'),
  },
  serviceProvider: {
    root: getFullPath(PATH.serviceProvider.root),
    invite: getFullPath(PATH.serviceProvider.root, PATH.serviceProvider.invite),
    finishInvitation: getFullPath(PATH.serviceProvider.root, PATH.serviceProvider.invite, 'finish'),
    orderFilter: getFullPath(PATH.order.filter, PATH.enterprise.order, 'service-providers'),
  },
  call: {
    root: getFullPath(PATH.call.root),
    subscribeOnUpdates: getFullPath(PATH.call.subscribeOnUpdates),
  },
  region: {
    root: getFullPath(PATH.region.root),
    orderFilter: getFullPath(PATH.order.filter, PATH.enterprise.order, 'regions'),
    tenantRegion: getFullPath(PATH.region.regionTenant),
  },
  order: {
    root: getFullPath(PATH.order.root),
    subscribeOnUpdates: getFullPath(PATH.order.root, PATH.common.subscribeOnUpdates),
  },
  enterprise: {
    root: getFullPath(PATH.enterprise.root),
    currency: getFullPath(PATH.enterprise.root, PATH.common.subEntity, PATH.enterprise.currency),
    orderFilter: getFullPath(PATH.order.filter, PATH.enterprise.order, 'owners'),
  },
  platformOperator: {
    root: getFullPath(PATH.platformOperator.root),
    currency: getFullPath(PATH.platformOperator.root, PATH.common.subEntity, PATH.platformOperator.currency),
    orderFilter: getFullPath(PATH.order.filter, PATH.enterprise.order, 'platform-operators'),
  },
  legalEntity: {
    root: getFullPath(PATH.legalEntity.root),
  },
  enterpriseDepartment: {
    root: getFullPath(PATH.enterpriseDepartment.root),
    orderFilter: getFullPath(PATH.order.filter, PATH.enterprise.order, 'departments'),
  },
  notification: {
    root: getFullPath(PATH.notification.root),
    urgent: getFullPath(PATH.notification.root, PATH.notification.urgent),
    markAsRead: getFullPath(PATH.notification.root, 'mark-as-read'),
    markAsDismissed: getFullPath(PATH.notification.root, 'mark-as-dismissed'),
    subscribeOnUpdates: getFullPath(PATH.notification.root, PATH.common.subscribeOnUpdates),
  },
  orderType: {
    root: getFullPath(PATH.orderType.root),
    orderFilter: getFullPath(PATH.order.filter, PATH.enterprise.order, 'types'),
  },
  orderView: {
    root: getFullPath(PATH.orderView.root),
    priority: getFullPath(PATH.orderView.root, PATH.orderView.priority),
  },
  file: {
    root: getFullPath(PATH.file.root),
    anonymously: getFullPath(PATH.file.root, 'anonymous'),
  },
  customField: {
    orderFilter: getFullPath(PATH.order.filter, PATH.enterprise.order, 'custom-fields'),
  },
  serviceCategoryGroup: {
    root: getFullPath(PATH.serviceCategoryGroup.root),
  },
  serviceCategory: {
    root: getFullPath(PATH.serviceCategory.root),
  },
  service: {
    root: getFullPath(PATH.service.root),
  },
  // serverEvent: {
  //   root: fullPath(PATH.serverEvent),
  //   task: {
  //     root: fullPath(PATH.serverEvent, PATH.serverEvent.task.root),
  //     all: fullPath(PATH.serverEvent, PATH.serverEvent.task.root, PATH.serverEvent.task.all),
  //     order: fullPath(PATH.serverEvent, PATH.serverEvent.task.root, PATH.serverEvent.task.order),
  //   },
  //   order: {
  //     root: fullPath(PATH.serverEvent, PATH.serverEvent.order.root),
  //     all: fullPath(PATH.serverEvent, PATH.serverEvent.order.root, PATH.serverEvent.order.all),
  //   },
  // },
  promotionsConfig: {
    root: getFullPath(PATH.promotionsConfig.root, PATH.promotionsConfig.config),
  },
  dictionary: {
    root: getFullPath(PATH.dictionary.root),
    promotions: getFullPath(PATH.promotionsConfig.root, PATH.dictionary.root),
    recommendations: getFullPath(PATH.recommendationsConfig.root, PATH.dictionary.root),
  },
  recommendationsConfig: {
    root: getFullPath(PATH.recommendationsConfig.root, PATH.recommendationsConfig.config),
  },
  matchingConfig: {
    root: getFullPath(PATH.matchingConfig.root),
  },
};

function getFullPath(...path: string[]): string {
  return `${BACKEND_ROOT}/${path.join('/')}`;
}
