import Router from './configs/routes/ReactRouterConfig';
import ThemeProvider from './theme';
import GlobalStyles from './theme/globalStyles';
import ScrollToTop from './components/hocsGlobal/ScrollToTop';
import { ProgressBarStyle } from './components/features/ProgressBar';
import NotistackProvider from './components/hocsGlobal/NotistackProvider';
import ThemeColorPresets from './components/hocsGlobal/ThemeColorPresets';
import ThemeLocalization from './components/hocsGlobal/ThemeLocalization';
import MotionLazyContainer from './components/features/animate/MotionLazyContainer';
import { useEffect, useState } from 'react';
import { useSelector } from 'storage';
import LoadingScreen from 'components/ui/loadingIndicators/LoadingScreen';
import { DIContextProvider } from 'hooks/_contexts/DIContext';
import DIContainer from 'services/DIContainer';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StableNavigateContextProvider } from 'hooks/_contexts/StableNavigateContext';
import LanguageServiceImpl from 'services/language/LanguageServiceImpl';
import Analytics from './components/hocsGlobal/Analytics';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';

// import Settings from './components/settings'; // кнопка справа для настроек темы

export default function App() {
  const { isInitialized, isProfileOutdated, isLoading: isAuthDataLoading, currentUser, permissions } = useSelector((state) => state.auth);
  const [diContainer, setDIContainer] = useState<null | DIContainer>(null);
  const [isLanguageDataOutdated, setIsLanguageDataOutdated] = useState(false);
  const onErrorCallback = (error: any) => console.error(error); // тут по хорошему счёту должен быть Notistack, но он ещё не инициализирован

  // 1. Инициализирует зависимости приложения на основе языка (который нужно ещё докачать)
  useEffect(() => {
    const onLangChangeHandler = () => setIsLanguageDataOutdated(true);
    const languageService = new LanguageServiceImpl(onLangChangeHandler);
    const initializeDI = () => {
      const diContainer = new DIContainer({ languageService });
      setDIContainer(diContainer);
    };
    languageService.getLocaleAsyncData().then(initializeDI).catch(onErrorCallback);
  }, []);

  // 2.1 Грузит данные текущего пользователя, если они были изменены (isProfileOutdated), перезагружает приложение (при первом запуске приложения isProfileOutdated всегда true)
  useEffect(() => {
    if (diContainer && isProfileOutdated) {
      diContainer.storageActions.auth.initialize(diContainer.storageActions.models);
    }
  }, [diContainer, isProfileOutdated]);

  // 2.2 Грузит данные языка, если он был изменён, перезагружает приложение
  useEffect(() => {
    if (diContainer && isLanguageDataOutdated) {
      const onLangChangeHandler = () => setIsLanguageDataOutdated(true);
      const languageService = new LanguageServiceImpl(onLangChangeHandler);
      const initializeDI = () => {
        setIsLanguageDataOutdated(false);
        setDIContainer(new DIContainer({ languageService }));
      };
      languageService.getLocaleAsyncData().then(initializeDI).catch(onErrorCallback);
    }
  }, [diContainer, isLanguageDataOutdated]);

  // 3. Получение глобальных сущностей по юзеру (пока здесь же и по языку, чтобы иметь один notificationSubscriptionState, дальше можно хранить его в локальном стейте)
  useEffect(() => {
    if (currentUser && diContainer) {
      const notificationActions = diContainer.storageActions.requestScope.notification();
      notificationActions.getList();
      const notificationSubscriptionState = notificationActions.subscribeOnUpdates(onErrorCallback);
      return () => {
        notificationActions.resetData();
        notificationActions.cancelRequests();
        notificationSubscriptionState.clearSubscription();
      };
    }
  }, [currentUser?.id, currentUser?.updatedAt.getTime(), diContainer?.services.language.getCurrentLocale().localeFull]);

  // Попдиска на SSE звонки
  useEffect(() => {
    if (currentUser && diContainer && permissions.can(MODEL_PERMISSION.CAN_SUBSCRIBE_CALLS)) {
      const callActions = diContainer.storageActions.requestScope.callNotification();
      const callSubscriptionState = callActions.subscribeOnUpdates(onErrorCallback);
      return () => {
        callActions.resetData();
        callActions.cancelRequests();
        callSubscriptionState.clearSubscription();
      };
    }
  }, [currentUser?.id, currentUser?.updatedAt.getTime(), diContainer?.services.language.getCurrentLocale().localeFull]);

  if (!diContainer || !isInitialized || isAuthDataLoading || isLanguageDataOutdated) {
    return <LoadingScreen />;
  }

  return (
    <LocalizationProvider
      dateAdapter={diContainer.statefulUtils.date.getAdapter()}
      adapterLocale={diContainer.statefulUtils.date.getLocale()}
    >
      <ThemeProvider>
        <ThemeColorPresets>
          <DIContextProvider diContainer={diContainer}>
            <ThemeLocalization>
              <NotistackProvider>
                <MotionLazyContainer>
                  <GlobalStyles />
                  <ProgressBarStyle />
                  <ScrollToTop />
                  <Analytics />
                  <StableNavigateContextProvider>
                    <Router />
                  </StableNavigateContextProvider>
                </MotionLazyContainer>
              </NotistackProvider>
            </ThemeLocalization>
          </DIContextProvider>
        </ThemeColorPresets>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
