import { CallNotificationResponseDTO, CreateCallNotificationRequestDTO } from 'typings/dto/call';
import ModelActionsService from '../ModelActionsService';
import ICallNotificationActionService from './ICallNotificationActionService';
import NavigateFrontendUtils from 'utils/NavigateFrontend';
import IServerEventsService from 'services/serverEvents/IServerEventsService';
import DateUtils from 'utils/Date';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';

export default class CallNotificationActionServiceImpl
  extends ModelActionsService<CallNotification, CreateCallNotificationRequestDTO, CallNotificationResponseDTO>
  implements ICallNotificationActionService
{
  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'createdAt', NavigateFrontendUtils.SORT_ORDER.desc);
  };

  public subscribeOnUpdates = (onErrorCallback: (error: any) => void): IServerEventsService.SubscriptionState => {
    const onMessageCallback = (data: IServerEventsService.SSEEntityUpdateMessageData) => {
      const { listOfEntities } = this.getStorageCurrentState();
      const isNotUniq = listOfEntities.find((e) => e.id === data.id);
      const updatedEntityDate = new Date(data.updatedAt);
      if (!DateUtils.isValidDate(updatedEntityDate)) {
        return onErrorCallback(new Error("Can't parse event date"));
      }
      const isCreated = data.type === IServerEventsService.SSE_ENTITY_UPDATE_TYPE.CREATED;
      const isDeleted = data.type === IServerEventsService.SSE_ENTITY_UPDATE_TYPE.DELETED;
      if (isNotUniq && !isDeleted) {
        return;
      }
      this.updateEntitiesList(isCreated, isDeleted, data.id).catch((error) => {
        onErrorCallback(error);
      });
    };

    return this.serverEventsService.createSubscription(PATH_BACKEND.call.subscribeOnUpdates, onMessageCallback, onErrorCallback);
  };

  private updateEntityList(
    list: CallNotification[],
    entity: CallNotification | null,
    isCreated: boolean,
    isDeleted: boolean
  ): CallNotification[] {
    if (!entity) return [...list];

    if (isDeleted) {
      return list.filter((e) => e.id !== entity.id);
    }

    if (isCreated) {
      return [...list, entity];
    }

    return [...list];
  }

  // TODO Сделать проверку, если через n-е кол-во времени на не прелетело isDeleted удалять из списка
  public async updateEntitiesList(isCreated: boolean, isDeleted: boolean, entityId: string): Promise<void> {
    await this.getByIdWithOrder(entityId);
    const { listOfEntities, oneEntity } = this.getStorageCurrentState();
    if (!oneEntity) {
      console.warn(`Entity with ID ${entityId} not found in storage after update.`);
      return;
    }
    const updatedList = this.updateEntityList(listOfEntities, oneEntity, isCreated, isDeleted);
    this.setList(updatedList);
  }

  public getListBackground = async (
    dto?: Record<string, any> | null,
    filter?: LocationSearchObject,
    customPath?: string
  ): Promise<void> => {
    try {
      const response = await this.requestList(dto, filter, customPath || this.getModelApiRootPath(dto));
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.setList(response));
      else console.warn(`Get list request ${this.modelStorageName} is outdated`);
    } catch (error) {
      if (!this.areActionsOutdated) {
        console.error(error);
        this.storageDispatch(this.modelStorageActions.setError(error));
      }
    }
  };

  // TODO Оптимизировать
  public getByIdWithOrder = async (entityId: string): Promise<void> => {
    try {
      const response = await this.requestById(entityId, PATH_BACKEND.call.root);
      if (!this.areActionsOutdated) {
        const responseOrder: Order = await this.entityApiService.getWithCredentials(`${PATH_BACKEND.client.order}/${response.orderId}`);
        responseOrder.id = response.id;
        this.storageDispatch(this.modelStorageActions.setOne({ ...response, ...responseOrder }));
      } else console.warn(`Get by id background request ${this.modelStorageName} is outdated`);
    } catch (error) {
      if (!this.areActionsOutdated) {
        console.error(error);
        this.storageDispatch(this.modelStorageActions.setError(error));
      }
    }
  };

  public getByIdBackground = async (id: string, customPath?: string, dto?: Record<string, any>): Promise<void> => {
    try {
      const response = await this.requestById(id, customPath || this.getModelApiRootPath(dto), dto);
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.setOne(response));
      else console.warn(`Get by id background request ${this.modelStorageName} is outdated`);
    } catch (error) {
      if (!this.areActionsOutdated) {
        console.error(error);
        this.storageDispatch(this.modelStorageActions.setError(error));
      }
    }
  };

  public closeCall = async (call: CallNotification): Promise<void> => {
    await this.updateEntitiesList(false, true, call.id);
  };
}
