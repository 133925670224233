/**
 * Как добавить новую модель в систему
 * - добавить название модели в enum MODEL_NAME (здесь)
 * - создать хранилище модели
 * - создать маппер модели*
 * - создать сервис экшинов модели*
 *
 * *прописать всё это в DIContainer
 * При пропуске любого пункта подскажет тайпскрипт.
 */
enum MODEL_NAME {
  serviceProvider = 'serviceProvider',
  enterprise = 'enterprise',
  platformOperator = 'platformOperator',
  brand = 'brand',
  serviceProviderLegalEntity = 'serviceProviderLegalEntity',
  enterpriseLegalEntity = 'enterpriseLegalEntity',
  userSP = 'userSP',
  userEnterprise = 'userEnterprise',
  userSuperadmin = 'userSuperadmin',
  userPlatformOperator = 'userPlatformOperator',
  /** Текущий пользователь */
  me = 'me',
  /** @todo переименовать - теперь это не только подразделения энтерпрайза, но и МК  */
  enterpriseDepartment = 'enterpriseDepartment',
  order = 'order',
  /**
   * Тот же самый order, только помеченный saas - права сильно различаются для него
   * @deprecated такого вроде уже в системе нет
   */
  orderSaas = 'orderSaas',
  ordersFilter = 'ordersFilter',
  orderReview = 'orderReview',
  orderReviewComment = 'orderReviewComment',
  orderComment = 'orderComment',
  orderProviderTransactionsData = 'orderProviderTransactionsData',
  invoice = 'invoice',
  region = 'region',
  widgetMatchSetting = 'widgetMatchSetting',
  task = 'task',
  orderReport = 'orderReport',
  serviceOrdered = 'serviceOrdered',
  orderHistory = 'orderHistory',
  call = 'call',
  orderType = 'orderType',
  notification = 'notification',
  orderView = 'orderView',
  serverAction = 'serverAction',
  country = 'country',
  priceClient = 'priceClient',
  priceTechnician = 'priceTechnician',
  priceServiceProvider = 'priceServiceProvider',
  pricePlatformOperator = 'pricePlatformOperator',
  pricelistServiceProvider = 'pricelistServiceProvider',
  pricelistPlatformOperator = 'pricelistPlatformOperator',
  /** Модель priceAny универсальная, в которой может прийти любой прайс. Там где должен прийти конкретный прайс используется конкретная модель */
  priceAny = 'priceAny',
  technicianTeam = 'technicianTeam',
  orderLabel = 'orderLabel',
  contractSP = 'contractSP',
  contractPO = 'contractPO',
  fundsTransaction = 'fundsTransaction',
  //
  serviceCategoryGroup = 'serviceCategoryGroup',
  serviceCategory = 'serviceCategory',
  service = 'service',
  /** Общие ресурсы, которые делят userSuperadmin, userPlatformOperator, userSP и userEnterprise */
  userBase = 'userBase',
  /** Общие ресурсы, которые делят serviceProviderLegalEntity и enterpriseLegalEntity */
  legalEntity = 'legalEntity',
  /** Кастомные поля, которые есть в фильтрах, могут отсутствовать в enterprise, уже нужно свое хранилище */
  customField = 'customField',
  promotionsConfig = 'promotionsConfig',
  recommendationsConfig = 'recommendationsConfig',
  dictionary = 'dictionary',
  orderDocument = 'orderDocument',
  matchingConfig = 'matchingConfig',
  callNotification = 'callNotification',
}
export default MODEL_NAME;
